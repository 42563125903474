import { CardProduct } from 'components/Cards/CardProduct'
import { Box } from '@achieve/ascend'
import { Carousel } from 'components/Carousels'
import styles from './CardCarousel.module.scss'
import { CardProductListItem } from 'components/Cards/CardProductListItem'

function Card({ item, productCardVariant }) {
  return (
    <div className={styles[`card-product-${productCardVariant.toLowerCase()}`]}>
      {item && productCardVariant === 'ProductCardsListItem' ? (
        <CardProductListItem content={item} />
      ) : (
        <CardProduct content={item} />
      )}
    </div>
  )
}

function CardCarousel({ content = {}, isMobile, productCardVariant }) {
  const containsCardBadge = Boolean(
    content.gridItems.findIndex((item) => item.fields?.cardBadgeImage) >= 0
  )
  if (productCardVariant === 'ProductCardsListItem') {
    return (
      <>
        {content && (
          <Box data-variant="carousel">
            <Carousel
              carouselItems={content?.gridItems || []}
              itemComponent={({ item: cardItem }) => (
                <Card item={cardItem} productCardVariant={productCardVariant} />
              )}
              variant="auto"
              idPrefix="value-props-carousel"
              className={`${styles['carousel-wrapper']}`}
              classNameItems={styles['carousel-item']}
              classNameContainer={styles['carousel-container']}
              classNameIndicators={styles['indicators']}
              classNameActiveDot={styles['active-indicator']}
              classNameControls={styles['carousel-wrapper-controls']}
              carouselThreshold={[1]}
            />
          </Box>
        )}
      </>
    )
  } else {
    return (
      <>
        {content && (
          <Carousel
            className={styles['product-card-carousel']}
            carouselItems={content?.gridItems || []}
            itemComponent={({ item: cardItem }) => (
              <Card item={cardItem} productCardVariant={productCardVariant} />
            )}
            variant="preview-stretch"
            idPrefix="value-props-carousel"
            withIndicators={isMobile}
            classNameIndicators={styles['indicators']}
            classNameActiveDot={styles['active-indicator']}
            withBadges={containsCardBadge}
          />
        )}
      </>
    )
  }
}

export { CardCarousel }
